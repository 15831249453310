<template>
  <b-modal
    visible
    centered
    size="s"
    hide-footer
    title="Деактивация подпрограммы"
    @hidden="onClose"
  >
    <template v-if="isDeactivationState">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <span class="d-block mb-2"><b>Причина деактивации</b></span>
      <div class="crm-wrapper-radio">
        <base-radio-button
          v-for="reason in reasonsDeactivation"
          :key="reason.value"
          v-model="deactivationObject.reason"
          :name="reason.value"
        >
          {{ reason.title }}
        </base-radio-button>
      </div>
      <div>
        <span class="d-block mb-2"><b>Комментарий к деактивации</b></span>
        <b-form-textarea
          v-model.trim="deactivationObject.comment"
          placeholder="Введите комментарий"
          rows="2"
          max-rows="8"
        />
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          class="button-import"
          @click="startDeactivation"
        >
          <span>Деактивировать</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BaseRadioButton } from '@/components/base';
import Preloader from '@/components/Preloader';

export default {
  name: 'InsuranceSubProgramDeactivateModal',
  components: {
    BaseRadioButton,
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    subprogram: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDeactivationState: false,
      deactivationObject: {
        reason: null,
        comment: null,
      },
      reasonsDeactivation: [
        {
          value: 'ProgramIsNotActive',
          title: 'Подпрограмма не активна',
        },
        {
          value: 'ProgramIsNotForSale',
          title: 'Подпрограмма не продается',
        },
      ],
    };
  },
  created() {
    this.deactivationObject.reason = 'ProgramIsNotActive';
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async startDeactivation() {
      try {
        this.isDeactivationState = true;

        this.$emit('deactivate', this.deactivationObject);
        this.onClose();
      } finally {
        this.isDeactivationState = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-wrapper-radio {
    & label {
      margin: 16px 0px;
    }
  }

  .info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }
</style>
